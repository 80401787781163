import { Stack } from "react-bootstrap";
import Tag from "../Tag";

import styles from "./../../asset/css/project-card.module.css";

function ProjectCard(props) {
  let target = "_self";
  props.button.newtab ? (target = "_blank") : (target = "_self");

  return (
    <div className={styles["projectCard"]}>
      <ProjectThumb img={props.thumb} />
      <div className={styles["projectCard__desc"]}>
        <Stack direction="horizontal" className="flex-wrap justify-content-center">
          {props.tags &&
            props.tags.map((tag, i) => (
              <Tag key={i} isActive={false} value={tag} />
            ))}
        </Stack>
        <h3>{props.name}</h3>
        <p>{props.desc}</p>

        <a
          className={`button ${styles["projectCard__button"]}`}
          href={props.button.url}
          target={target}
          rel="noreferrer"
        >
          {props.button.name}
        </a>
      </div>
    </div>
  );
  /*<button className="d-block d-md-none btnCollapse">collapse</button>*/
}
export default ProjectCard;

function ProjectThumb(props) {
  return (
    <div
      className="thumb"
      style={{ backgroundImage: "url(./images/projects/" + props.img + ")" }}
    ></div>
  );
}
