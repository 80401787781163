//import React, {useState} from "react";
import tagStyles from "./../asset/css/tag.module.css";

const Tag = (props) => {

//const [tagStage, setTagState] = useState()

  return (
    <div>
      {props.isActive && 
      <button className={tagStyles.tagChip__active} onClick={props.onClick}>
      {props.value}
      </button>
      }
      {!props.isActive && 
      <div className={tagStyles.tagChip}>
      {props.value}
      </div>
      }
      
    </div>
    
  );
};
export default Tag;
