import { Container, Row, Col } from "react-bootstrap"

function Introduce(props){
    return(
        <section className="introduce">
            <Container>
                <Row className="justify-content-center">
                    <Col sm={12} md={6}>
                    {props.children}
                    </Col>
                </Row>
            </Container>
        </section>
    )
}
export default Introduce