import { Stack } from "react-bootstrap"
import Tag from "./Tag"

const HotTags = props =>{
    const tagClickHandler = e => props.onGetTag(e.target.innerHTML)
    
    return(
        <Stack direction="horizontal" className="justify-content-center">
            {props.data.map(data => 
            <Tag 
            key={data.id} 
            isActive={true}
            value={data.name}
            onClick={tagClickHandler}
            />)
            }
        </Stack>
    )

}
export default HotTags