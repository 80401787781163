import React from 'react'
import ReactDOM from 'react-dom/client'
import reportWebVitals from './reportWebVitals'

import TagManager from 'react-gtm-module'

import Head from './comp/Head'
import App from './comp/App'
import Footer from './comp/Footer'
import CookieConsent from './comp/CookieConsent'

import './custom.scss'
import './index.css'
import './asset/css/mainStyle.css'

import JsonData from './data/home-content.json'

const tagManagerArgs = {
  gtmId: 'GTM-WZLR25P'
}
TagManager.initialize(tagManagerArgs)

const root = ReactDOM.createRoot(document.getElementById('root'))

root.render(
  <React.StrictMode>
    <Head />
    {JsonData && JsonData.map(data => <App 
      key="default"
      introduce={data.introduce} 
      projects={data.projects_head} 
      social={data.social_head}
      />)}
    {JsonData && JsonData.map(data => <Footer key="siteFooter">&copy;{data.copyright}</Footer>)}
    <CookieConsent />

  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals()
