import { Col, Container, Row } from "react-bootstrap"

function Footer(props){
    return(
        <section className="siteFooter">
            <Container>
                <Row className="align-items-end">
                    <Col className="text-center">
                        <p className="caption content-white">{props.children}</p>
                    </Col>
                </Row>
            </Container>
        </section>
    )
}
export default Footer