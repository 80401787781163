import Introduce from './Introduce';
import Projects from './Projects/Projects';
import Social from './Social';
//import LinkedInBadge from './LinkedInBadge';

import './../asset/css/App.css';

function App(props) {

  return (
    <div className="contentWrapper" key="contentWrapper">
          <Introduce key="home_intro">{props.introduce}</Introduce>
          <Projects key="projects" title={props.projects} />
          <Social key="social" title={props.social} />
    </div>
  );
}

export default App;
