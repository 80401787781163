
import { Stack } from "react-bootstrap";

import ProjectCard from "./ProjectCard";

import styles from "./../../asset/css/projects.module.css";

const ProjectsList = (props) => {


  return (
    <Stack
      direction="horizontal"
      className={`${styles["projectsWrap"]} flex-wrap justify-content-between`}
      >
      {props.data && props.data
          .sort((a, b) => (a.weight > b.weight ? 1 : -1))
          .map((card) =>
          card.visible ? (
              <ProjectCard
                key={card.id}
                thumb={card.thumb}
                name={card.name}
                desc={card.desc}
                tags={card.tags}
                button={card.button}
              />
            ) : (""))
        }
    </Stack>
  );
};
export default ProjectsList;
