import React from 'react'
import { Stack } from 'react-bootstrap';
import Container from 'react-bootstrap/Container'

class CookieConsent extends React.Component{
    constructor(props){
        super(props)
        this.state = {
            hadConsent : false
        }
        // This binding is necessary to make `this` work in the callback
        this.handleClick = this.handleClick.bind(this);
    }

    handleClick() {
        this.setState(prevState => ({
            hadConsent: !prevState.hadConsents
        }));
    }

    render(){
        return(
            <section id="cookieConsent" className={this.state.hadConsent ? "d-none" : "d-block"}>
                <Container>
                    <Stack direction='horizontal' className='justify-content-center align-items-center'>
                        <p className="caption content-white">We use cookies without your personal data to ensure that we give you the best experience on our website. If you continue to use this site we will assume that you are happy with it.</p>
                        <button className="small" onClick={this.handleClick}>OK</button>
                    </Stack>
                </Container>
            </section>
        )
    }
    
}
export default CookieConsent