import React, {useState} from 'react'
import { Col, Container, Row } from 'react-bootstrap'

import ProjectData from './../../data/projects.json'
import HotTagsData from './../../data/hot-tags.json'

import HotTags from './../HotTags'
import ProjectsList from './ProjectsList'
import styles from './../../asset/css/projects.module.css'

const Projects = props => {

    const getFilterTag = tag => {
        tag==="all"?
        setFilteredProject(ProjectData) 
        :setFilteredProject(ProjectData.filter(d => d.tags.includes(tag)))
    }

    const [filteredProjects, setFilteredProject] = useState(ProjectData)
    
    return(
        <section className={styles["projects"]}>
            <Container>
                <Row>
                    <Col className="text-center"><h2>{props.title}</h2></Col>
                </Row>
                <Row>
                    <HotTags data={HotTagsData} onGetTag={getFilterTag} />
                </Row>
                <Row>
                    <ProjectsList data={filteredProjects} />
                </Row>
            </Container>
        </section>
    )

}
export default Projects