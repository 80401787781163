//import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

import SocialData from './../data/social.json'
import './../asset/css/social.css'
import { Col, Container, Row } from 'react-bootstrap'

function Social(props){
    return(
        <section className="social">
            <Container>
                <Row>
                    <Col className='text-center'>
                    <h2>{props.title}</h2>
                        { SocialData && SocialData.map( data => {
                            return(
                                <a key={data.id} href={data.url} target="_blank" rel="noreferrer">
                                    <i className={data.icon}></i>
                                </a>
                            )
                        }) 
                        }
                    </Col>
                </Row>
            </Container>
        </section>
    )
}
export default Social